import { OmniElement, OmniStyle, OmniIcon, html, choose } from 'omni-ui';
import { om2alert, om2confirm, Om2Table, om2FormModal } from 'omni-campaign-ui';
import { api } from './helpers/api.js';
import { showLoader } from './helpers/util.js';
import { OmniAppContainerMixin } from 'omni-app-container';
import './step-app-links.js';
import './step-assets.js';
import './step-modules.js';
import './workflow-settings.js';
import './workflow-statuses.js';
import { hasRole } from './helpers/util.js';

OmniStyle.register();
OmniIcon.register();
Om2Table.register();

/**
 * This is a base class for the workflow-view, workflow-stage and workflow-step classes
 * which all essentially list steps in a table in one form or another.
 * We may want to split some of this logic up into the three subclasses.
 */
export default class WorkflowStepContainer extends OmniAppContainerMixin(
  OmniElement
) {
  constructor() {
    super();
    this.tabs = [];
    this.data = {};
  }

  static get properties() {
    return {
      data: { attribute: false },
      tab: { attribute: false },
      tabs: { attribute: false },
      stages: { type: Object },
      stepId: this.routeParamProperty({ name: 'stepId' }),
      stageId: this.routeParamProperty({ name: 'stageId' }),
      workflowId: this.routeParamProperty({ name: 'workflowId' }),
    };
  }

  firstUpdated() {
    if (!this.data) this.reload();
  }

  shouldUpdate(changedProperties) {
    if (changedProperties.has(this.loadDataProperty)) {
      this.reload();
    }
    return true;
  }

  async reload() {
    showLoader(this, async () => {
      this.dispatchNewEvent('breadcrumb-refresh');
      this.data = await this.loadData();
      this.dispatchNewEvent('breadcrumb-refresh');
    });
  }

  /**
   * @description Clones a step on the same framework template
   * @param {object} step - information related to a given stage on a campaign
   */
  cloneStep = step => {
    om2FormModal({
      title: `Clone ${step.name}`,
      onSubmit: obj => api.cloneStep(step.uuid_step, obj),
      onSuccess: () => this.reload(),
      schema: {
        name: {
          title: 'Name',
          type: 'string',
          default: `${step.name} [cloned]`,
        },
        description: {
          title: 'Description',
          type: 'string',
          default: step.description,
        },
        display_order: {
          title: 'Display Order',
          type: 'number',
          min: 1,
          multipleOf: 1,
          default: (this.data.steps?.length ?? 0) + 1,
        },
        display_order_label: {
          title: 'Display Order Label',
          type: 'string',
        },
        ...(this.tab === 'Steps' && {
          parent_id: {
            title: 'Assigned Stage',
            type: 'dropdown',
            enum: this.stages,
            default: this.data.uuid_step,
          },
        }),
      },
    });
  };

  addStep() {
    const data = {};
    const parentIdKey = this.stepParentIdKeyOverride || 'parent_id';
    data[parentIdKey] = this.stageId ?? this.workflowId;
    const stepTypes =
      this.stepLabel === 'Step'
        ? {
            description: {
              title: 'Description',
              type: 'string',
            },
            display_order_label: {
              title: 'Display Order Label',
              type: 'string',
              placeholder: `Step ${(this.data.steps?.length ?? 0) + 1}`,
            },
          }
        : {};

    const dataPoints = hasRole('super admin')
      ? {
          data_points: {
            title: 'Data Points',
            type: 'object',
            default: {},
            formModal: { fullWidth: true },
          },
        }
      : {};
    om2FormModal({
      title: `Add ${this.stepLabel}`,
      data,
      schema: {
        name: {
          title: 'Name',
          type: 'string',
        },
        display_order: {
          title: 'Display Order',
          type: 'number',
          min: 1,
          multipleOf: 1,
          default: (this.data.steps?.length ?? 0) + 1,
        },
        ...stepTypes,
        ...dataPoints,
      },
      onSubmit: api.createStep,
      onSuccess: () => this.reload(),
    });
  }

  async editStep({ data, stepId, onSuccess } = {}) {
    if (!data && stepId) {
      // eslint-disable-next-line no-param-reassign
      data = await api.getStep(stepId);
    }
    if (!data || !stepId) return;

    const stepIndex = this.data.steps?.findIndex(
      step => step.uuid_step === data.uuid_step
    );

    const stepTypes =
      this.stepLabel === 'Step'
        ? {
            description: {
              title: 'Description',
              type: 'string',
            },
            display_order_label: {
              title: 'Display Order Label',
              type: 'string',
              placeholder: `Step ${stepIndex + 1}`,
            },
          }
        : {};
    const dataPoints = hasRole('super admin')
      ? {
          data_points: {
            title: 'Data Points',
            type: 'object',
            default: {},
            formModal: { fullWidth: true },
          },
        }
      : {};
    om2FormModal({
      title: `Edit ${this.stepLabel}`,
      data,
      onlySubmitChanges: true,
      onSubmit: obj => api.updateStep(stepId, obj),
      onSuccess,
      schema: {
        name: {
          title: 'Name',
          type: 'string',
        },
        display_order: {
          title: 'Display Order',
          type: 'number',
          min: 1,
          multipleOf: 1,
        },
        ...stepTypes,
        ...dataPoints,
      },
    });
  }

  deleteStep = step => {
    const callback = async () => {
      try {
        await api.deleteStep(step.uuid_step);
        this.reload();
      } catch (e) {
        const { errors, status } = e.json;
        if (status === 403) {
          om2alert(
            'Delete action unavailable because this framework template is live. You will need to clone and swap out in order to make this change.'
          );
        } else {
          om2alert(errors);
        }
      }
    };

    om2confirm(`Permanently delete step "${step.name}"?`, {
      callback,
      type: 'warning',
    });
  };

  calcAvailableTabs() {
    // Figure out what tabs to show. The result here will change as we initialize.
    if (!this.tab || !this.tabs.includes(this.tab)) {
      [this.tab] = this.tabs; // Assign to first tab
    }
  }

  renderTabs() {
    if (this.tabs?.length === 1)
      return html`<span slot="header-start">${this.tabs[0]}</span>`;

    return this.tabs.map(
      tab => html`
        <button
          slot="header-start"
          class="button is-shadowless is-medium ${this.tab === tab
            ? 'is-primary is-active'
            : 'is-text is-alt-1'}"
          @click=${() => {
            this.tab = tab;
          }}
        >
          ${tab}
        </button>
      `
    );
  }

  renderAddButton() {
    const disabled =
      this.data.steps_locked && ['Steps', 'Stages'].includes(this.tab);
    const clickHandler = () => {
      if (this.tab === 'Steps' || this.tab === 'Stages') {
        this.addStep();
      } else {
        this.shadowRoot
          .getElementById('main-table')
          .firstElementChild.dispatchEvent(new Event('doAdd'));
      }
    };

    return html` <span
      slot="header-end"
      class="is-inline-block"
      title=${disabled
        ? `Add action unavailable because this framework template is live. You will need to clone this framework template in order to add addtional ${this.tab?.toLowerCase()}.`
        : 'Add'}
    >
      <button
        @click=${clickHandler}
        class="button is-small is-outlined"
        ?disabled=${disabled}
      >
        Add
      </button>
    </span>`;
  }

  renderHeaderSlots() {
    return [this.renderTabs(), this.renderAddButton()];
  }

  renderNoChildren() {
    this.calcAvailableTabs();

    // used to determine proper verbiage for Steps and Stages tabs
    const labels = {
      Step: this.tab === 'Stages' ? 'Stage' : 'Step',
      steps: this.tab === 'Stages' ? 'stages' : 'steps',
    };

    return html`
      <omni-style @reload=${() => this.reload()}>
        <!-- Render table content for the current tab-->
        <div id="main-table">
          ${choose(
            this.tab,
            [
              [
                'Modules',
                () =>
                  html`<step-modules
                    .data=${this.data.step_modules}
                    uuid=${this.stepId}
                  >
                    ${this.renderHeaderSlots()}
                  </step-modules>`,
              ],
              [
                'App Links',
                () =>
                  html`<step-app-links
                    .data=${this.data.apps}
                    uuid=${this.stepId}
                  >
                    ${this.renderHeaderSlots()}
                  </step-app-links>`,
              ],
              [
                'Guides',
                () =>
                  html`<step-assets
                    .data=${this.data.assets}
                    uuid=${this.stepId}
                  >
                    ${this.renderHeaderSlots()}
                  </step-assets>`,
              ],
              [
                'Settings',
                () =>
                  html`<workflow-settings
                    .settingsData=${this.data.settings}
                    .assignedClients=${this.data.assigned_clients}
                    workflowId=${this.workflowId}
                  >
                    ${this.renderHeaderSlots()}
                  </workflow-settings>`,
              ],
              [
                'Statuses',
                () =>
                  html`<workflow-statuses
                    .statuses=${this.data.statuses}
                    .disabled=${this.data.steps_locked}
                    workflowId=${this.workflowId}
                  >
                    ${this.renderHeaderSlots()}
                  </workflow-statuses>`,
              ],
            ],
            /* Steps and Stages (default case) */
            () =>
              html`<om2-table
                autosort="display_order,asc"
                autotooltip
                shadowed
                .search=${['name', 'uuid_step']}
                .columns=${[
                  {
                    label: 'name',
                    key: 'name',
                    isSortable: true,
                    isMain: true,
                  },
                  {
                    label: 'id',
                    key: 'uuid_step',
                    isSortable: true,
                  },
                  {
                    label: 'order',
                    key: 'display_order',
                    isSortable: true,
                  },
                  ...(this.tab === 'Stages'
                    ? []
                    : [
                        {
                          label: 'order label',
                          key: 'display_order_label',
                          isSortable: true,
                          passthrough: true,
                          template: (step = {}) => html`
                            <td>
                              ${step.display_order_label?.trim() ||
                              html`
                                <span class="is-italic">
                                  ${`Step ${step.display_order}`}
                                </span>
                              `}
                            </td>
                          `,
                        },
                        {
                          label: 'description',
                          key: 'description',
                          isSortable: true,
                        },
                      ]),
                  {
                    label: 'actions',
                    passthrough: true,
                    template: step => {
                      /**
                       * Establish empty array to store delete function objects for use in om2-icon-menu
                       * @example
                       * {
                       *  label: Determines option name,
                       *  callback: Callback function to be executed on option select,
                       * }
                       */

                      const stepActions = [
                        {
                          label: 'Clone',
                          callback: this.cloneStep,
                        },
                        {
                          label: 'Delete',
                          callback: this.deleteStep,
                        },
                      ];

                      return html` <td>
                        <button
                          @click=${() =>
                            this.editStep({
                              stepId: step.uuid_step,
                              onSuccess: () => this.reload(),
                              label: labels.Step,
                            })}
                          class="button is-text"
                          title="Edit"
                        >
                          <omni-icon
                            class="is-size-2"
                            icon-id="omni:interactive:edit"
                          ></omni-icon>
                        </button>

                        <button
                          @click=${() => this.manageStep(step)}
                          class="button is-text ml-4"
                          title="Manage"
                        >
                          <omni-icon
                            class="is-size-2"
                            icon-id="omni:interactive:launch"
                          ></omni-icon>
                        </button>
                        <om2-icon-menu
                          class="ml-4"
                          .options="${stepActions}"
                          iconId="omni:interactive:actions"
                          iconClass="is-size-2"
                          tooltip="Clone/Delete"
                          @change="${e => e.detail.value.callback(step)}"
                          ?disabled=${this.data.steps_locked}
                        >
                        </om2-icon-menu>
                      </td>`;
                    },
                  },
                ]}
                .data="${this.data?.steps ?? []}"
              >
                ${this.renderHeaderSlots()}
                ${!this.data?.steps ||
                (this.data.steps && this.data.steps.length === 0)
                  ? html`<div class="has-text-centered p-5" slot="body">
                      <i>
                        ${this.data?.steps
                          ? `There are currently no ${labels.steps}.`
                          : 'Loading...'}
                      </i>
                    </div>`
                  : ''}
              </om2-table>`
          )}
        </div>
      </omni-style>
    `;
  }

  render() {
    return html`<slot>${this.renderNoChildren()}</slot>`;
  }
}
