export const showLoader = async (node, fnOrPromise) => {
  try {
    node.dispatchNewEvent('app-layout-loading');
    const result = fnOrPromise.then ? await fnOrPromise : await fnOrPromise();
    return result;
  } finally {
    node.dispatchNewEvent('app-layout-ready');
  }
};

const sleep = ms =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export { sleep };

export const hasRole = role =>
  window.AN.sso.user.projects
    .find(project => project.typeKey === 'campaign_admin')
    ?.userRoles?.includes(role);
